<template>
<div id="page-articulos">
    <div>
        <v-breadcrumbs :items="breadcrumbs">
            <v-icon slot="divider">forward</v-icon>
        </v-breadcrumbs>
    </div>
    <v-container fluid fill-height>
        <v-row align-center justify-center>
            <v-col>
                <base-material-card color="primary" icon="library_books" title="Conteo de Inventarios" class="elevation-1 px-5 py-3">
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn x-small fab dark color="success" @click.native="descripcion_conteo=''; modal_descripcion=true" title="Nuevo Conteo" v-tippy>
                            <v-icon>add</v-icon>
                        </v-btn>
                    </v-card-actions>

                    <v-card-text>
                        <div id="datagrid" v-on:keyup.enter="registros.items = []; $refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');">

                            <v-client-table ref="vuetable" :data="registros.items" :columns="columns" :options="options" class="elevation-2 pa-2">
                                <template slot="noResults">
                                    <v-alert :value="true" icon="info" color="info">No se encontró ningún registro</v-alert>
                                </template>

                                <template slot="articulos" slot-scope="props">
                                    {{props.row.articulos.length}}
                                </template>
                                <template slot="fecha" slot-scope="props">
                                    {{parseDateTime(props.row.fecha)}}
                                </template>

                                <template slot="actions" slot-scope="props">
                                    <v-btn x-small fab dark color="pink" @click.native="eliminar(props.row._id, props.row._rev )" title="Eliminar Registro" v-tippy>
                                        <v-icon>delete</v-icon>
                                    </v-btn>&nbsp;
                                    <v-btn x-small fab dark color="blue" @click.native="openconteo(props.row._id)" title="Editar Conteo" v-tippy>
                                        <v-icon>edit</v-icon>
                                    </v-btn>&nbsp;
                                    <v-btn x-small fab dark color="success" @click.native="openModalReporte(props.row)" title="Reporte Conteo" v-tippy>
                                        <v-icon>receipt</v-icon>
                                    </v-btn>

                                </template>
                            </v-client-table>
                            <PaginacionComponent ref="pagination_pw" :props="props_paginacion"></PaginacionComponent>
                        </div>
                    </v-card-text>
                </base-material-card>
            </v-col>
        </v-row>
    </v-container>

    <v-dialog v-model="modalReporte" max-width="90%">
        <v-card>
            <v-card-title>
                <v-card class="primary white--text titulomodal">
                    <v-icon class="white--text">edit</v-icon>&nbsp;
                    <span class="subheading">
                        <strong>Detalle del Conteo de Inventario</strong>
                    </span>
                </v-card>
                <v-spacer></v-spacer>
                <v-btn icon @click.native="modalReporte = false" class="close_modal">
                    <v-icon class="white--text">cancel</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-actions>
                <v-spacer></v-spacer>
                    <exportar-excel :data="articulos" :exportFields="encabezadoArticulos" name="Reporte_Conteo_Inventario.xlsx">
                        <v-btn dark small color="green darken-2" title="Exportar a Excel" v-tippy>
                        <v-icon>mdi-file-export</v-icon> Exportar a Excel
                    </v-btn>                    
                </exportar-excel>
                &nbsp;
                <v-btn :loading="loading" color="primary" small @click.native="cargarExcel()" title="Cargar Excel de Inventario Anterior">
                    <v-icon>mdi-file-import</v-icon> Comparar Excel Inv. Anterior
                </v-btn>
                
                <upload-excel-component v-show="false" :firstRow="2" title="Cargar Inventario Anterior" idInput="inventarioAnterior" :on-success="handleSuccess" :before-upload="beforeUpload" /> 
                
                
          </v-card-actions>
            <v-card-text>
                
                    <v-container>
                        
                            <v-client-table :key="keyTable" ref="vuetablearticulos" :data="articulos" :columns="columnsArticulos" :options="optionsArticulos" class="elevation-2 pa-2">
                                <template slot="noResults">
                                    <v-alert :value="true" icon="info" color="info">No se encontró ningún registro</v-alert>
                                </template>
                                
                                <template slot="diferencia_conteo_anterior" slot-scope="props">
                                    <div v-if="parseFloat(props.row.diferencia_conteo_anterior)>0" class="green_class">
                                        {{ formatNumber( parseFloat(props.row.diferencia_conteo_anterior).toFixed(2),2) }}
                                    </div>
                                    <div v-else-if="parseFloat(props.row.diferencia_conteo_anterior)<0" class="red_class">
                                        {{ formatNumber( parseFloat(props.row.diferencia_conteo_anterior).toFixed(2),2) }}
                                    </div>
                                    <div v-else >
                                        {{ props.row.diferencia_conteo_anterior!=undefined?formatNumber( parseFloat(props.row.diferencia_conteo_anterior).toFixed(2),2):'' }}
                                    </div>
                                </template>

                                <template slot="diferencia_precio_compra" slot-scope="props">
                                    {{ formatNumber( parseFloat(props.row.diferencia_precio_compra).toFixed(2)) }}
                                </template>
                                <template slot="diferencia_precio_compra_con_iva" slot-scope="props">
                                    {{ formatNumber( parseFloat(props.row.diferencia_precio_compra_con_iva).toFixed(2)) }}
                                </template>
                            </v-client-table>
                        
                    </v-container>
                
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click.native="modalReporte = false">
                    <v-icon>cancel</v-icon> Cerrar
                </v-btn>
            </v-card-actions>

        </v-card>
    </v-dialog>

    <v-dialog v-model="modal_descripcion" max-width="40%">
        <v-card>
            <v-card-title>
                <v-card class="primary white--text titulomodal">
                    <v-icon class="white--text">edit</v-icon>&nbsp;
                    <span class="subheading">
                        <strong>Crear Nuevo Conteo de Inventario</strong>
                    </span>
                </v-card>
                <v-spacer></v-spacer>
                <v-btn icon @click.native="modal_descripcion = false" class="close_modal">
                    <v-icon class="white--text">cancel</v-icon>
                </v-btn>
            </v-card-title>            
            <v-card-text>
                <v-form ref="form_conteo" lazy-validation>
                    <v-container>
                        <v-row justify="center">
                            <v-text-field v-model="descripcion_conteo" :rules="[rules.required]"
                            v-on:keydown.enter.prevent='1' @keyup.enter="nuevo_conteo()"
                            label="Descripción del Conteo" placeholder="Ingrese descripción del conteo">
                            </v-text-field>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click.native="modal_descripcion = false">
                    <v-icon>cancel</v-icon> Cerrar
                </v-btn>
                <v-btn color="success" @click.native="nuevo_conteo()">
                    <v-icon>done</v-icon> Crear Conteo
                </v-btn>
            </v-card-actions>

        </v-card>
    </v-dialog>

</div>
</template>

<script>
import Vue from "vue";
import PaginacionComponent from '@/components/PaginacionComponent.vue';
import moment from 'moment';
import {
    mapState,
    mapMutations
}

from "vuex";
export default {
    components: {
        "PaginacionComponent": PaginacionComponent
    },

    watch: {
        model_filters: {
            handler(val) {
                // this.$nextTick(() => {
                //     this.registros.items = [];
                //     this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
                // });
            },
            deep: true
        }
    },
    mounted: function () {
        this.model_filters = this.$refs.vuetable.$refs.table.query;
        if (!this.verificaPermiso('s.inventarios.conteo')) {
            this.$router.replace("AccessDenied").catch(() => {});
        }
    },
    created: function () {
        this.sucursal_activa = this.$local_storage.get("sb_sucursal","0");
        if(this.sucursal_activa == "0"){ //Si en otra pestaña cambiaron a sucursal TODAS recargamos la pagina
            this.$router.go();            
        }
    },

    data() {
        return {
            keyTable:"tablearticulos",
            descripcion_conteo: "",
            modal_descripcion: false,
            sucursal_activa:"",
            encabezadoArticulos: {
                "Código de Barras": "codigo_articulo",
                "Artículo": "nombre_articulo",
                "Descripción":"descripcion",
                "Fecha Conteo": "fecha",
                "Cantidad Contada": "cantidad",
                "Existencia al momento del conteo": "existencia",
                "$ Compra":"precio_compra",
                "$ Compra C/Impuestos":"precio_compra_con_iva",
                "Diferencia":"diferencia",
                "Diferencia $ Compra":"diferencia_precio_compra",
                "Diferencia $ Compra C/Impuestos":"diferencia_precio_compra_con_iva"
            },
            encabezadoArticulosOriginal: {
                "Código de Barras": "codigo_articulo",
                "Artículo": "nombre_articulo",
                "Descripción":"descripcion",
                "Fecha Conteo": "fecha",
                "Cantidad Contada": "cantidad",
                "Existencia al momento del conteo": "existencia",
                "$ Compra":"precio_compra",
                "$ Compra C/Impuestos":"precio_compra_con_iva",
                "Diferencia":"diferencia",
                "Diferencia $ Compra":"diferencia_precio_compra",
                "Diferencia $ Compra C/Impuestos":"diferencia_precio_compra_con_iva"
            },
            modalReporte:false,
            model_filters: "",
            props_paginacion: {
                infiniteHandler: this.infiniteHandler,
                total_registros: 0
            },
            active_tab: "datos",
            menu1: false,
            fecha_parsed: "",
            marcas: [],
            productos: [],
            unidades: [],
            articulos: [],
            impuestos: [],
            categorias: [],
            tipos_precios: [],
            fab: [],
            name: "datagrid",
            fechaUsuario: "",
            columnsArticulosOriginal:["codigo_articulo","nombre_articulo","descripcion","fecha","cantidad","existencia",
            "precio_compra","precio_compra_con_iva","diferencia","diferencia_precio_compra","diferencia_precio_compra_con_iva"],
            columnsArticulos:["codigo_articulo","nombre_articulo","descripcion","fecha","cantidad","existencia",
            "precio_compra","precio_compra_con_iva","diferencia","diferencia_precio_compra","diferencia_precio_compra_con_iva"],
            optionsArticulos: {
                filterable: ["codigo_articulo","nombre_articulo","fecha","cantidad","existencia",
                "precio_compra","precio_compra_con_iva","diferencia","diferencia_precio_compra","diferencia_precio_compra_con_iva"],
                sortable: ["codigo_articulo","nombre_articulo","fecha","cantidad","existencia","diferencia",
                "precio_compra","precio_compra_con_iva","diferencia_precio_compra","diferencia_precio_compra_con_iva"],
                orderBy: {
                    column: 'fecha',
                    ascending: false
                },
                headings: {
                    "codigo_articulo":"Código de Barras",
                    "nombre_articulo":"Artículo",
                    "fecha":"Fecha Conteo",
                    "cantidad":"Cantidad Contada",
                    "existencia":"Existencia al momento del conteo",
                    "precio_compra":"$ Compra",
                    "precio_compra_con_iva":"$ Compra C/Impuestos",
                    "diferencia":"Diferencia",
                    "diferencia_precio_compra":"Diferencia $ Compra",
                    "diferencia_precio_compra_con_iva":"Diferencia $ Compra C/Impuestos"
                },
            },
            columns: [
                "usuario",
                "fecha",
                "descripcion",
                "articulos",
                "actions"
            ],
            show_loading: true,
            loading:false,
            options: {
                filterable: ["usuario","descripcion",
                    "fecha",
                ],
                sortable: ["usuario","descripcion",
                    "fecha",
                ],
                orderBy: {
                    column: 'fecha',
                    ascending: false
                },
                headings: {
                    "usuario": "Usuario",
                    "fecha": "Fecha",
                    "descripcion":"Descripción",
                    "articulos": "Articulos",
                    actions: "Operaciones"
                },
            },
            breadcrumbs: [{
                    text: "Inicio",
                    disabled: false,
                    to: "/index"
                },
                {
                    text: "Inventario",
                    disabled: true,
                    href: ""
                },
                {
                    text: "Conteo",
                    href: ""
                }
            ],

            modal: false,

            update: false,

            model: {
                _id: "",
                _rev: "",
                type: "lotes_conteo",
                id_sucursal: "",
                nombre_sucursal: "",
                usuario: "",
                fecha: "",
                estatus: "Activo",
                articulos: [],
            },
            rules: {
                required: v => !!v || "Este campo es requerido",
            },
            search: "",
            registros: {
                selected: [],
                items: []
            },
            articulos_all: [],

        };
    },

    methods: {
        beforeUpload(file) {
            var self = this;
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (isLt2M) {                                
                return true;
            }
            this.$swal({
                type: "warning",
                title: "¡Operación no Permitida!",
                text: "El archivo no debe ser mayor a 2MB.",
                footer: ""
            });
            
            return false;
        },
        cargarExcel() {
            var el = document.getElementById("inventarioAnterior");
            el.click();
        },
        infiniteHandler: function ($state) {
            var rows_per_page = 10;
            let self = this;
            var page = Math.ceil(this.registros.items.length / rows_per_page); // == 1
            var skip = page * rows_per_page;
            let where = {
                "type":"lotes_conteo",
                "id_sucursal":self.sucursal_activa
            };

            this.options.filterable.forEach(x => {
                if (this.model_filters[x]) {
                    where[x] = {
                        "$regex": "(?i)" + this.model_filters[x].toString().replace("(","\\(").replace(")","\\)").replace("+","\\+")
                    }
                }
            });

            axios({
                    method: 'POST',
                    url: process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/',
                    withCredentials: true,
                    data: {
                        limit: rows_per_page,
                        skip: skip,
                        selector: where,
                        sort: [{
                            "fecha": "desc"
                        }],
                        use_index:"_design/f5f8ae4322fa53a403194bf0432f0c88c230bc9e"
                    },
                }).then(response => {
                    if (response.data.docs.length) {
                        var unique_records = new Set(this.registros.items.map(item => item._id));
                        unique_records = Array.from(unique_records.values());
                        var nuevos = response.data.docs.filter(e => !unique_records.includes(e._id));
                        this.registros.items = this.registros.items.concat(nuevos);                        
                        this.props_paginacion.total_registros = this.registros.items.length;

                        $state.loaded();
                    } else {
                        $state.complete();
                    }
                })
                .catch(error => {
                    console.log(error)
                })
        },
        formatNumber: function (x) {
            if (x == undefined) return 0;
            x = x.toString();
            var pattern = /(-?\d+)(\d{3})/;
            while (pattern.test(x)) x = x.replace(pattern, "$1,$2");
            return x;
        },
        openModalReporte:function(row){            
            var self = this;
            window.dialogLoader.show('Espere un momento por favor..');
            //Iniciamos en su original
            this.encabezadoArticulos = JSON.parse(JSON.stringify(this.encabezadoArticulosOriginal));
            this.columnsArticulos = JSON.parse(JSON.stringify(this.columnsArticulosOriginal));
            
            window.axios
                .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + row._id)
                .then(response => {
                    row = response.data;
                    this.articulos = JSON.parse(JSON.stringify(row.articulos));
                    this.articulos.forEach(function(e){
                        e.fecha = self.parseDateTime(e.fecha);
                        e.codigo_articulo = e.codigo_articulo[0];                        
                    });
                    this.modalReporte = true;
                })
                .catch(error => {
                    this.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "Ocurrió un error al obtener el registro. Intente nuevamente",
                        footer: ""
                    });
                }).then(()=>{
                    window.dialogLoader.hide();
                });
        },

        openconteo(id) {
            this.$local_storage.set("sb_lote_conteo", id);
            this.$router.replace("/inventario/escanear").catch(() => {});

        },
        openModal: function (type) {
            if (type === 1) {
                this.update = false;

                this.model.created_at = window.moment().format("YYYY-MM-DDTHH:mm:ss");
                this.fechaUsuario = window.moment(this.model.created_at).format("DD-MM-YYYY");
            } else {
                this.update = true;
            }

            this.modal = true;
            this.active_tab = "datos";
        },
        formatDate(date) {
            if (!date) return null

            const [year, month, day] = date.split('-')
            return `${day}-${month}-${year}`
        },
        parseDate(date) {
            if (!date) return null

            const [month, day, year] = date.split('-')
            return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
        },
        parseDateTime(date) {
            if (!date) return null

            return moment(String(date)).format('DD/MM/YYYY hh:mm');
        },
        validaFloat: function (campo) {
            const pattern = /^[\d]*(\.{0,1}[\d]*)$/;
            let value = this.model[campo];
            if (!pattern.test(value)) {
                this.model[campo] = this.model[campo].substring(0, this.model[campo].length - 1);
                this.validaFloat(campo);
                //console.log("INVALIDO")
            }
            //else
            //console.log("VALIDO");

        },

        nuevo_conteo: function () {
            if(this.$refs.form_conteo.validate()){
                window.dialogLoader.show('Espere un momento por favor..');
                let data = {
                    type: this.model.type,
                    id_sucursal: this.sucursal_activa,
                    usuario: this.$local_storage.get("sb_usuario"),
                    fecha: window.moment().format("YYYY-MM-DDTHH:mm:ss"),
                    descripcion: this.descripcion_conteo, 
                    articulos: [],
                    };
                    window.axios
                    .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/', data)
                    .then(response => {
                        if (response.data.ok) {
                                this.$local_storage.set("sb_lote_conteo", response.data.id);
                                this.$router.replace("/inventario/escanear").catch(() => {});
                        }
                    })
                    .catch(error => {
                        this.$swal({
                            type: "error",
                            title: "¡Operación no Permitida!",
                            text: "Ocurrió un error al guardar el registro.",
                            footer: ""
                        });
                        console.log(error);
                    }).then(() => {
                        this.descripcion_conteo = "";
                        this.modal_descripcion = false;
                        window.dialogLoader.hide();
                    });
            }           

        },
        eliminar: async function(_id, _rev) {
            let self = this;

            this.$swal({
                type: "info",
                title: "Cuidado",
                text: "¿Está seguro de eliminar el registro?",
                footer: "",
                showCancelButton: true,
                cancelButtonColor: "#d33",
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Aceptar",
                cancelButtonText: "Cancelar"
            }).then(result => {
                if (result.value ) {
                    window.dialogLoader.show('Espere un momento por favor..');
                    window.axios
                        .delete(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + _id + "?rev=" + _rev)
                        .then(response => {
                            window.dialogLoader.showSnackbar('El registro se eliminó correctamente..', {
                                color: 'success'
                            });
                            this.items = [];
                            this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
                        }).catch(error => {
                            window.dialogLoader.showSnackbar('Ocurrió un error al eliminar el registro..', {
                                color: 'error'
                            });
                        }).then(() => {
                            window.dialogLoader.hide();
                            this.registros.items = []; 
                            this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
                        });

                }

            });


        },
        
        handleSuccess: async function({
            results,
            header
        }) {                
                
            var errores = 0;
            var erroresText = '';
            var importData = {};
            try {
                window.dialogLoader.show('Espere un momento por favor..');
                this.loading = true;
            
                var self = this;   
                
                //for (var k in results) {
                                        
                self.getArticulosAll().then(async result => {
                    //Valida qué exista el codigo_barras
                    await Promise.all(results.map((r) => {
                        window.dialogLoader.show('Espere un momento por favor..');
                        if (r['Código de Barras'] && r['Código de Barras'].toString().trim() != ""
                            && r['Cantidad Contada'] && r['Cantidad Contada'].toString().trim() != "")
                        {

                            var search = self.articulos.find(e => e.codigo_articulo.toString() == r['Código de Barras'].toString().trim());
                            if (search) {
                                search.conteo_anterior = parseFloat(r['Cantidad Contada']);
                                search.diferencia_conteo_anterior = parseFloat(search.cantidad) - search.conteo_anterior;
                            } else {
                                var art = self.articulos_all.find(e => e.codigo_barras.includes(r['Código de Barras'].toString().trim()));
                                if(art){
                                    self.articulos.push({
                                        "id_articulo": art["_id"],
                                        "codigo_articulo": r['Código de Barras'],
                                        "nombre_articulo": art["nombre"],
                                        "descripcion": art["descripcion"],
                                        "fecha": "",
                                        "cantidad": "NO SE ENCONTRÓ EN EL CONTEO ACTUAL",
                                        "existencia": 0,
                                        "precio_compra": 0,
                                        "precio_compra_con_iva": 0,
                                        "diferencia": 0,
                                        "diferencia_precio_compra": 0,
                                        "diferencia_precio_compra_con_iva": 0,
                                        "conteo_anterior":parseFloat(r['Cantidad Contada']),
                                        "diferencia_conteo_anterior": parseFloat(r['Cantidad Contada']) * -1, //Dejamos en negativo
                                    });
                                }
                                
                            }
                            
                        }
                    }));
                
                                                    
                    //Agregar columnas a la vista                    
                    self.columnsArticulos.push("conteo_anterior");
                    self.columnsArticulos.push("diferencia_conteo_anterior");
                    self.optionsArticulos.headings["conteo_anterior"] = "Conteo Anterior";
                    self.optionsArticulos.headings["diferencia_conteo_anterior"] = "Diferencia Conteo Anterior";
                    //Agregamos campos al de exportar
                    self.encabezadoArticulos["Conteo Anterior"] = "conteo_anterior";
                    self.encabezadoArticulos["Diferencia Conteo Anterior"] = "diferencia_conteo_anterior";
                    
                    //recargar tabla
                    self.keyTable = moment(new Date()).format("x"); //Refrescar tabla 
                                                    
                    self.$swal({
                        type: "success",
                        title: "¡Operación Exitosa!",
                        text: "La comparación se realizó exitosamente.",
                        footer: "",
                    }).then(()=>{
                        window.dialogLoader.hide();
                        self.loading = false;
                    });

                }).catch(error => {
                    window.dialogLoader.hide();
                    self.$swal({
                    type: "error",
                    title: "¡Operación no Permitida!",
                    text: "Ocurrió un error al obtener los artículos.",
                    footer: error
                    });
                });
                
            
            } catch (error) {
                console.log("ERROR: ", error);
                this.$swal({
                    type: "warning",
                    title: "¡Operación no Permitida!",
                    text: "Error al cargar el archivo. Verifique que no existan celdas vacías.",
                    footer: "",
                });
                window.dialogLoader.hide();
                this.loading = false;
            }

        },
        getArticulosAll: function(opcion="") {
            var self = this;      
            return new Promise(function(resolve, reject) {

                let data = {
                    "selector": {
                        "type": "articulos"
                    },
                    "fields":["_id","nombre","descripcion","codigo_barras"]
                };

                window.axios
                .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
                .then(response => {            
                    self.articulos_all = [];            
                    if (response.data.docs.length > 0) {
                        self.articulos_all = response.data.docs                                      
                    }
                    return resolve(true);
                })
                .catch(error => {
                    return reject(error);
                });
            });
        },

    }
};
</script>

<style scoped>
.green_class{
    background-color: lightgreen;
}

.red_class {
    background-color: lightcoral;
}
.footer-hide>>>.VuePagination {
    display: none;
}

.theme--light.v-tabs>.v-tabs-bar .v-tab:not(.v-tab--active),
.theme--light.v-tabs>.v-tabs-bar .v-tab:not(.v-tab--active)>.v-icon,
.theme--light.v-tabs>.v-tabs-bar .v-tab--disabled {
    color: black;
    font-size: small;
    background-color: #EEEEEE;
    padding: 0px;
}

.v-tabs .v-tabs-bar .v-tab.v-tab--active {
    color: white;
    font-size: small;
    background-color: #df7205;
    padding: 0px;
    font-weight: bold;

}

.v-tabs-slider-wrapper {
    left: 0 !important;
}

.table-bordered thead th,
.table-bordered thead td {
    font-size: x-small !important;
}
</style>
